import Navigation from '../Components/Navigation'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Container, Grid, Stack, Typography , Accordion, AccordionActions, AccordionDetails, AccordionSummary,} from '@mui/material';
import BannerImage from '../Assets/Banner Image.jpg'
import manImage from '../Assets/Overlap Image.jpg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../Style/Home.css'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ProdcutsSlide, ServicesSlides, SliderImages, aboutContentList, mediaClient,  testimonialItems } from '../Variables/Variables';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';
import icon from '../Assets/synchronization_8328532.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import PlayCircleSharpIcon from '@mui/icons-material/PlayCircleSharp';

const CustomPrevArrow = (props) => (
    <Button {...props} className="BlogButton" id='backWardArrow'>
      <ArrowBackIcon sx={{ verticalAlign: 'middle' }} />
    </Button>
  );
  
  const CustomNextArrow = (props) => (
    <Button {...props} className="BlogButton" id='frontArrow'>
      <ArrowForwardIcon sx={{ verticalAlign: 'middle' }} />
    </Button>
  );
  

  const CustomSlidePrevArrow = (props) => (
    <Button {...props} className="BlogButton1" id='SlideImagebackWardArrow'>
      <PlayCircleSharpIcon size='large' sx={{fontSize:'50px' , verticalAlign: 'middle', color:'#fff' , opacity:'0.5' }} />
    </Button>
  );
  
  const CustomSlideNextArrow = (props) => (
    <Button {...props} className="BlogButton1" id='SlideImagefrontArrow'>
      <PlayCircleSharpIcon  size='large' sx={{fontSize:'50px' , verticalAlign: 'middle' , color:'#fff'  , opacity:'0.5'}} />
    </Button>
  );

function HomePage() {

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  
    handleResize(); // Call handleResize on initial render
  
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
    
      const [isVisible, setIsVisible] = useState(false);
      const animationRef = useRef(null);
      const navigate = useNavigate();
      const sliderRef = useRef(null);
      const [expandedPanels, setExpandedPanels] = useState([]);
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const elementPosition = animationRef.current ? animationRef.current.offsetTop : 0;
    
        if (scrollPosition > elementPosition) {
          setIsVisible(true);
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    const settings3 = {
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed:500,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        arrows: size.width < 1023 ? false : true, 
        beforeChange: (current, next) => setActiveIndex(next),
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 999,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 340,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        const settings4 = {
          slidesToShow: 2,
          slidesToScroll: 1,
          adaptiveHeight: true,
          autoplay: true,
          speed:500,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
          arrows: size.width < 1023 ? false : true, 
          beforeChange: (current, next) => setActiveProductIndex(next),
          responsive: [
              {
                breakpoint: 1025,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 999,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 340,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };

        const settings2 = {
            slidesToShow: 6,
            adaptiveHeight: true,
            autoplay: true,
            slidesToScroll: 1,
            arrows:false,
            responsive: [
                {
                  breakpoint: 1025,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 999,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow:2,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 340,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            };

            const CustomDot = ({onClick, active , i}) => (
              <div
               className={`custom-dot ${active ? 'active' : ''}`}
               onClick={onClick}
              />
            );
      
            const settings1 = {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
              autoplay: true,
              speed:200,
              dots: true,
              prevArrow: <CustomSlidePrevArrow />,
              nextArrow: <CustomSlideNextArrow />,
              arrows: size.width < 1023 ? false : true, 
              beforeChange: (current, next) => setActiveSliderIndex(next),
              customPaging: i => (
                <div
                className={`custom-dot ${ i === activeSliderIndex ? 'active' : ''}`}
                />
              )
              };
    
        const [activeIndex, setActiveIndex] = useState(0);
        const [activeProductIndex, setActiveProductIndex] = useState(0);
        const [activeSliderIndex, setActiveSliderIndex] = useState(0);

        const ServiceSlideItems =
        ServicesSlides &&
        ServicesSlides.map((i, index) => (
          <Box key={index} className={`${index === activeIndex ? 'serviceBg' : 'serviceBg1'}`}>
            <Stack spacing={{lg:2 , md:2 , sm:1 , xs:1}} className='textBox'>
            <Typography fontWeight={700} className={`${index === activeIndex ? 'titletext' : 'titletext1'}`}>{i.title}</Typography>
            <Typography className={`${index === activeIndex ? 'contenttext' : 'contenttext1'}`}>{i.content}</Typography>
                {
                index === activeIndex && 
                <Box pt={1}>
                <Button onClick={()=>handleClickOpenNameEdit(i.title)} variant='outlined' sx={{ color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>Read More</Button>
                </Box>
                }
            </Stack>
          </Box>
        ));


        const ProductSlideItems =
        ProdcutsSlide &&
        ProdcutsSlide.map((i, index) => (
          <Box key={index}>
            <Box
              className={`${index === activeProductIndex ? 'serviceBg' : 'serviceBg1'}`}
            >
            <Stack textAlign='center' spacing={2} className='textBox'>
            <Typography fontWeight={700} className={`${index === activeProductIndex ? 'titletext' : 'titletext1'}`}>{i.title}</Typography>
            <Box px={2}>
            <ul>
            {i.content.split(';').map((item, i) => (
            <li key={i}  textAlign='left' style={{marginBottom:'10px'}}>
            <Typography  textAlign='left' className={`${index === activeProductIndex ? 'contenttext' : 'contenttext1'}`}>{item.trim()}</Typography>
            </li>
            ))}
            </ul>
            </Box>
                {
                index === activeProductIndex && 
                <Box pt={1}>
                <Button onClick={()=>handleClickOpenNameProductEdit(i.title)} variant='outlined' sx={{ color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>Read More</Button>
                </Box>
                }
            </Stack>
            </Box>
          </Box>
        ));

        const handleClickOpenNameEdit = (title) =>{
         const slideCategoryId = title.replace(/\s+/g, '').toLowerCase();
         navigate(`/services#${slideCategoryId}`);
     
         const scrollTimeout = setTimeout(() => {
           const element = document.getElementById(slideCategoryId);
           if (element) {
             element.scrollIntoView({ behavior: "smooth" });
           }
         }, 0);
       }


       const handleClickOpenNameProductEdit  = (title) =>{
        const slideCategoryId = title.replace(/\s+/g, '').toLowerCase();
        navigate(`/products#${slideCategoryId}`);
    
        const scrollTimeout = setTimeout(() => {
          const element = document.getElementById(slideCategoryId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0);
      }

      
        const mediaClientlideItems = mediaClient && mediaClient.map((i)=>{
            return(
              <Box height={180} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
              <img src={i. image}  alt='poster' style={{width:150 , height:150 , objectFit:'contain' , verticalAlign:"middle"}} />
              </Box>
            )
          })
          

          const words = ["SAP Solutions for Seamless Success"];
          const [part, setPart] = useState('');
          const [i, setI] = useState(0);
          const [offset, setOffset] = useState(0);
          const [forwards, setForwards] = useState(true);
          const [skipCount, setSkipCount] = useState(0);
          const skipDelay = 5;
          const speed = 20;
        
          useEffect(() => {
            const wordFlick = setInterval(() => {
              if (forwards) {
                if (offset >= words[i].length) {
                  setSkipCount((prevSkipCount) => prevSkipCount + 1);
                  if (skipCount === skipDelay) {
                    setForwards(false);
                    setSkipCount(0);
                  }
                }
              } else {
                if (offset === 0) {
                  setForwards(true);
                  setI((prevI) => (prevI + 1) % words.length);
                  setOffset(0);
                }
              }
              const newPart = words[i].substr(0, offset);
              if (skipCount === 0) {
                if (forwards) {
                  setOffset((prevOffset) => prevOffset + 1);
                } else {
                  setOffset((prevOffset) => prevOffset - 1);
                }
              }
              setPart(newPart);
            }, speed);
        
            return () => clearInterval(wordFlick);
          }, [forwards, i, offset, skipCount]);


          useEffect(() => {
            const slideCategoryId = window.location.hash.substring(1);
        
            window.scrollTo({ top: 0, behavior: 'smooth' });
        
            setTimeout(() => {
              const element = document.getElementById(slideCategoryId);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 0);
          }, []);


          const SliderItems =
          SliderImages &&
          SliderImages.map((i, index) => (
            <Box key={index} position='relative'>
              <img src={i.images} alt='' className='SliderImagePreview' />
            </Box>
          ));


          const settings = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            arrows:false,
            autoplaySpeed: 1000,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                }
              },
              {
                breakpoint: 970,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };


          const TestimonialslideItems =testimonialItems  && testimonialItems .map((i) => {
            return (
                <Box p={2} bgcolor='#202020' borderRadius='30px'  key={i.name} className='testSlider'>
                  
                  <Box
                    display="flex"
                    flexDirection={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }}
                    justifyContent="space-between"
                    alignContent="center"
                    gap={{ lg: 0, md: 0, sm: 2, xs: 2 }}
                    alignItems="center"
                  >
                    <Box display="flex" flexDirection="row" gap={2} justifyContent='start' alignContent="center" alignItems="center">
                      <img src={i.image} alt="thumb" style={{width:80 ,height:80, objectFit:'cover',  objectFit:'contain',  verticalAlign: "middle" , borderRadius:'50%'}} />
                      <Typography color='#B56A30'>{i.title}</Typography> 
                    </Box>
                  </Box>

                   <Box mt={1} flexWrap='wrap' width='80%'>
                    <Typography variant="p" fontWeight={500} fontSize="16px" lineHeight={1.2}>
                      {i.content}
                    </Typography>
                  </Box>

                </Box>
            )});


            const togglePanel = (index) => {
              let updatedPanels = [];
              // If the selected panel is already open, close it
              if (expandedPanels.includes(index)) {
                updatedPanels = expandedPanels.filter((item) => item !== index);
              } else {
                // Otherwise, open the selected panel and close others
                updatedPanels = [index];
              }
              setExpandedPanels(updatedPanels);
            };

    return (
        <Box>
            <Box sx={{ backgroundColor: '#292929' }}>
                <Navigation />

                <Grid container mb={4} position='relative'>
                    <Grid item xs={12}>
                    <Box width='100%' position='relative'>
                        <Slider {...settings1} ref={sliderRef}>
                          {
                          SliderItems
                          }
                    </Slider>
                        </Box>
                        <Box className='sliderText' textAlign='center'>
                          <Typography variant='h3' fontWeight={600} color='#fff'>SAP Mastery: Forging Innovation with Integrity</Typography>
                          <Typography variant='h5' fontWeight={400} color='#fff' mt={2} lineHeight={1}>Empowering with SAP Excellence, Innovating Integrities</Typography>
                        </Box>
                      </Grid>
                    </Grid>

                {/* <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <img src={BannerImage} alt="" width={'100%'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} mt={4} style={{ color: 'white', textAlign: 'left ' }}>
                    <Box className='headingText1' >Duties and Responsibilities</Box> 
                    <Box className='headingText1'  width='100%'> 
                    <span> of Every <span style={{ color: '#C7854C', textDecoration:'underline', textDecorationColor:'#C7854C' }}>Consultant</span></span>
                    </Box>
                    <Stack spacing={3} mt={2}>
                    <h4 style={{ color: 'white', fontWeight: '500' }}>Can reach the target in a short time </h4>
                        <h3 id='text' style={{ color: 'white', fontWeight: '500' }}>Consultant have the task of providing professional advice and solutions for individuals targets planned by their clients so that they can be achived perfectly.</h3>
                  
                      <Box pt={2}>
                      <Grid container>
                        <Grid item xs={12} style={{ color: 'white', textAlign: 'left ' }}>
                        <h3 id='text' style={{ color: 'white', fontWeight: '500', marginTop:'10px' }}>Consultant have the task of providing professional advice and solutions for individuals targets planned by their clients so that they can be achived perfectly.</h3>
                        </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                    </Grid>
                </Grid> */}
          

            <Box sx={{ backgroundColor: '#202020' }}>
                <Box>
                <Grid container>

                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ color: 'white', textAlign: 'left ' }} px={2}>
                        <Box mt={4}>
                    <Button className='WebButton'>ABOUT US</Button>
                    </Box>
                       <Stack spacing={3} mt={2}>
                         <h1 style={{ fontSize: '50px' }}>SAP Solutions for Seamless Success</h1>
                          <h3 style={{ color: 'white', fontWeight: '500' }}>Consultant have the task of providing professional advice and solutions for individuals targets planned by their clients so that they can be achived perfectly.Consultant have the task of providing professional advice and solutions for individuals targets planned by their clients so that they can be achived perfectly.</h3>
                        <Box pt={3} pb={2}>
                        <Button onClick={()=>navigate('/contact')} variant='outlined' sx={{ color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff' }}>CONTACT US <Button sx={{ml:2}} className='BlogButton'> <ArrowForwardIcon /></Button></Button>
                        </Box>
                       </Stack>
                       <Box px={2} py={4}>
                          <Grid container spacing={3}>
                            {
                              aboutContentList?.map((i)=>{
                                return(
                                  <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box display='flex' flexDirection='row' gap={1}>
                                    <FiberManualRecordSharpIcon sx={{verticalAlign:'middle', color:'#B56A30'}} /> 
                                     <Typography>{i.item}</Typography>
                                    </Box>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                       </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} py={{lg:0, md:0, sm:1 , xs:1}}>
                        <Box display='flex' justifyContent='end'>
                        <img src={manImage} alt="" style={{width :'100%' , maxHeight:'800px'}} />
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>

            <Box sx={{ backgroundColor: '#010417', color: 'white' }} py={5} pb={10}>
            <Box px={4}>
                <Grid container columnSpacing={5}>
                    <Grid  item xs={12} sm={12} md={5} lg={5} style={{ color: 'white', textAlign: 'left ' }}>
                    <Box mt={4} mb={1}>
                    <Button className='WebButton'>SERVICES</Button>
                    </Box>
                    <Box className='headingText2'>Services  We're</Box> 
                    <Box className='headingText2' width='100%'> 
                    <span>Offering</span>
                    </Box>
                  
                        <Typography variant='subtitle1' sx={{ color: 'white', fontWeight: '500', marginTop:'10px', marginBottom:'30px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Typography>
                        <Button onClick={()=>navigate('/services')} variant='outlined' sx={{textTransform:'uppercase', color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>View All Services <Button sx={{ml:2}} className='BlogButton'> <ArrowForwardIcon /></Button></Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} pt={{lg:0 , md:0, sm:5 , xs:10}} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
                        <Box width='100%'>
                        <Slider {...settings3}>
                    {
                     ServiceSlideItems
                    }
                    </Slider>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>



            <Box sx={{ backgroundColor: '#010417', color: 'white' }} py={5} pb={10}>
            <Box px={4}>
                <Grid container columnSpacing={5}>
                    <Grid  item xs={12} sm={12} md={5} lg={5} style={{ color: 'white', textAlign: 'left ' }}>
                    <Box mt={4} mb={1}>
                    <Button className='WebButton'>PRODUCTS</Button>
                    </Box>
                    <Box className='headingText2'>Products  We're</Box> 
                    <Box className='headingText2' width='100%'> 
                    <span>Offering</span>
                    </Box>
                  
                    <Typography variant='subtitle1' sx={{ color: 'white', fontWeight: '500', marginTop:'10px', marginBottom:'30px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</Typography>
                        <Button onClick={()=>navigate('/products')} variant='outlined' sx={{textTransform:'uppercase', color:'#fff' ,  borderRadius:'10px' , borderColor:'#fff'}}>View All Products <Button sx={{ml:2}} className='BlogButton'> <ArrowForwardIcon /></Button></Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} pt={{lg:0 , md:0, sm:5 , xs:10}} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
                        <Box width='100%'>
                       { ProdcutsSlide && ProdcutsSlide.map((i, index)=>{
                        return(
                          <Accordion
                          key={index}
                          expanded={expandedPanels.includes(index)}
                          onChange={() => togglePanel(index)}
                          sx={{ boxShadow: 2,
                           border: 'none' , 
                           background:'#010417',
                           marginBottom:1,
                           borderRadius:'40px',
                           '&.Mui-expanded': {
                            background:'transparent',
                          },
                          }} 
                          
                      >
                          <AccordionSummary 
                           sx={{
                            color: expandedPanels.includes(index) ? '#B56A30' : 'inherit',
                           
                          }}
                          >
                             <Box display='flex' px={2} py={1} textAlign='left' justifyContent='space-between' width='100%'  
                           sx={{ boxShadow: 2,
                           border: 'none' , 
                           background:'#B56A30',
                           marginBottom:1,
                           borderRadius:'40px',
                           '&.Mui-expanded': {
                            background:'transparent',
                          },
                          display:'flex',
                          justifyContent:'space-between',
                          alignContent:'center',
                          alignItems:'center',
                          }} >
                             <Typography variant='h6' fontWeight={550} textAlign='left'>
                             {i.title}
                             </Typography>
                             <ExpandMoreIcon sx={{fontSize:'40px' , verticalAlign:'top', my:-0.5}} />
                             </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Box textAlign='left' px={8}>
                              <ul style={{textDecorationColor:'#fff'}}>
                              {i.content.split(';').map((item, i) => (
                              <li key={i}  textAlign='left' style={{marginBottom:'10px'}}>
                              <Typography sx={{ textAlign: 'left', fontSize: 17, color: 'your-color-here' }}>{item.trim()}</Typography>
                              </li>
                              ))}
                              </ul>
                              </Box>
                          </AccordionDetails>
                      </Accordion>
                        )
                       })}
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Box>




            <Box sx={{ backgroundColor: '#010417', color: 'white' }} py={7}>
            <Box px={{lg:4 , md:4 , sm:2 , xs:1}}>
            <Grid container>
            <Grid item xs={12} style={{ color: 'white', textAlign: 'left ' }}>
            <Box>
            <Button className='WebButton'>Testimonials</Button>
            </Box>
           
            <Box textAlign='center' py={2}>
            <h1>Testimonials</h1>
            </Box>

          <Box width='100%'>
          <Slider {...settings}>
          {TestimonialslideItems}
          </Slider>
          </Box>
                
                </Grid>
                </Grid>
                </Box>
            </Box>


        <Box sx={{ backgroundColor: '#010417', color: 'white' }} pt={2}>
        <Box pb={3}>
        <Typography variant='h4' textAlign='center' fontWeight={550}>
         Key Clients
        </Typography>
        </Box>
        <Box bgcolor='#dfeeee' pt={4}>
        <Slider {...settings2}>
        {
        mediaClientlideItems
        }
        </Slider>
        </Box>
        </Box>

       <Footer />


        </Box>
        </Box>
    );
}

export default HomePage;